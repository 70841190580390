.App {
  text-align: center;
  font-size: 18px;
  max-width: 1440px;
  margin: 0 auto;

  i {
    margin-right: 5px;
  }

  &__content {
    img {
      max-width: 100%;
    }

    p {
      padding: 10px 20px;
      text-align: left;
    }
  }

  &__info {
    padding: 10px 0;
    display: grid;
    grid-template-columns: 1fr;

    @media (min-width: 600px) {
      grid-template-columns: 1fr auto;
    }

    .qr {
      width: 100%;
      height: auto;
      min-width: 240px;
    }

    &__buttonSet {
      display: flex;
      flex-direction: column;
      padding: 0 10px;

      div {
        flex: 1;
        display: flex;
        align-items: center;

        form {
          display: flex;
        }
      }

      .button,
      Button {
        color: black;
        background-color: #f18023;
        border-color: transparent;
        border-radius: 8px;
        padding: 16px;
        margin: 8px;
        flex: 1;
        text-decoration: none;
        box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
        display: block;
        border: none;
        font-size: 20px;

        @media (min-width: 768px) {
          font-size: 24px;
        }

        &:hover {
          color: #333333;
          text-decoration: none;
          background-color: #dddddd;
          cursor: pointer;
        }
      }
    }
  }
}
